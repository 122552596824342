import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Container from '@objects/container'
import Button from '@objects/button'
import TracklineBackground from '@objects/tracklineBackground'
import Parallax from '@objects/parallax'
import breakpoint from '@utils/breakpoint'

import Tabs, { TabHead, TabPanel } from '@objects/tabs'

const ImageWrapper = styled.div.attrs({
  className: 'absolute left-0 top-0 pin-c w-full h-full',
})``

const ImageFirst = styled.div.attrs({
  className: 'absolute left-0 top-0 w-full h-full',
})``

const ImageSecond = styled.div.attrs({
  className: 'absolute left-0 top-0 w-full h-full ease-in-out transition',
})`
  opacity: 0;
`

const CategoryWrapper = styled.div`
  height: 700px;
  ${breakpoint('md')`
    height: 650px`}
`

function CategoryTabs({ items }) {
  const backgroundImageSecondRef = useRef(null)
  const [background, setBackground] = useState(items[0].image)
  const [backgroundSecond, setBackgroundSecond] = useState(items[0].image)

  function backgroundImages(newImage) {
    backgroundImageSecondRef.current.style.opacity = 0
    backgroundImageSecondRef.current.style.transitionDuration = '0ms'
    setBackgroundSecond(newImage)

    setTimeout(() => {
      backgroundImageSecondRef.current.style.opacity = 1
      backgroundImageSecondRef.current.style.transitionDuration = '500ms'
    }, 150)

    setTimeout(() => {
      setBackground(newImage)
    }, 650)
  }

  function renderTabPanel(item) {
    return (
      <div className="bg-white bg-opacity-85 rounded-sm p-7 max-w-391 mt-10 md:mt-0">
        <Headline level={2} ariaLabel={item.headline}>
          {item.headline}
        </Headline>
        <TeaserCopy className="mt-2 md:mt-4 mb-6 md:mb-7" html={item.copy} />
        <Button className={'btn-large'} link={item.button.link}>
          {item.button.label}
        </Button>
      </div>
    )
  }

  function renderImage(background, showAlt) {
    return (
      <Img
        className="h-full"
        style={{ height: '750px' }}
        fluid={{ ...background.fluid, media: `(min-width: 0px)` }}
        backgroundColor="grey"
        alt={showAlt ? background.description : ''}
      />
    )
  }

  return (
    <Container width="lg" role="region" aria-label="CategoryTabs">
      <TracklineBackground track={4} center width={400} />
      <TracklineBackground track={4} right center mirror width={400} />
      <CategoryWrapper className="relative px-3 lg:px-9 -mx-3 lg:m-0">
        <Parallax
          fixed
          className="absolute h-full w-full"
          styleInner={{ width: '100%', height: '100%' }}
          styleOuter={{ width: '100%', height: '100%', left: 0, right: 0 }}
        >
          <ImageWrapper>
            <ImageFirst>{renderImage(background, true)}</ImageFirst>
            <ImageSecond ref={backgroundImageSecondRef}>
              {renderImage(backgroundSecond, false)}
            </ImageSecond>
          </ImageWrapper>
        </Parallax>
        <Tabs activeTab={0}>
          <TabHead onClick={() => backgroundImages(items[0].image)}>
            {items[0].title}
          </TabHead>
          <TabHead onClick={() => backgroundImages(items[1].image)}>
            {items[1].title}
          </TabHead>
          <TabHead onClick={() => backgroundImages(items[2].image)}>
            {items[2].title}
          </TabHead>
          <TabPanel>{renderTabPanel(items[0])}</TabPanel>
          <TabPanel>{renderTabPanel(items[1])}</TabPanel>
          <TabPanel>{renderTabPanel(items[2])}</TabPanel>
        </Tabs>
      </CategoryWrapper>
    </Container>
  )
}

CategoryTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      }),
      image: PropTypes.object.isRequired,
    })
  ),
}

export default CategoryTabs
