import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Headline from '@objects/headline'
import TeaserCard from '@objects/teaserCard'
import Button from '@objects/button'
import Container from '@objects/container'
import TracklineBackground from '@objects/tracklineBackground'

function BigFiveBlogCard({ className, headline, button, cards, options }) {
  return (
    <>
      <Container
        className="pb-0 pt-7 pb-7 md:mt-13 md:pb-0 md:pt-0 md:pl-0"
        nopadding
        role="region"
        aria-label="BigFiveBlogCard"
      >
        <TracklineBackground track={1} center width={400} />
        <TracklineBackground track={4} right center mirror width={400} />
        <Headline className="text-center mb-7 md:mb-9" ariaLabel={headline}>
          {headline}
        </Headline>
        <div className="space-y-9 md:space-y-0 md:flex md:flex-row-reverse md:justify-between md:-mx-3">
          <div className="md:w-full md:px-3">
            {cards[0] && (
              <TeaserCard
                className={clsx('mb-9 lg:w-full')}
                type="stageTopic"
                square="sm"
                parallax
                bigTeaser
                role="listitem"
                {...cards[0]}
              />
            )}
          </div>
        </div>

        <div
          data-testid="list"
          className={clsx(className, 'md:flex md:flex-wrap md:-mx-3')}
          role="list"
          id="bloglist"
        >
          {cards[1] && (
            <TeaserCard
              className={clsx('md:px-3', 'mb-9 lg:w-1/2')}
              type="blog"
              square="sm"
              parallax
              reverse
              bigTeaser
              role="listitem"
              {...cards[1]}
            />
          )}

          {cards[2] && (
            <TeaserCard
              className={clsx('md:px-3', 'mb-9 lg:w-1/2')}
              type="blog"
              square="sm"
              parallax
              reverse
              bigTeaser
              role="listitem"
              {...cards[2]}
            />
          )}
          {cards[3] && (
            <TeaserCard
              className={clsx('md:px-3', 'mb-9 lg:w-1/2')}
              type="blog"
              square="sm"
              parallax
              reverse
              bigTeaser
              role="listitem"
              {...cards[3]}
            />
          )}
          {cards[4] && (
            <TeaserCard
              className={clsx('md:px-3', 'mb-9 lg:w-1/2')}
              type="blog"
              square="sm"
              parallax
              reverse
              bigTeaser
              role="listitem"
              {...cards[4]}
            />
          )}
        </div>

        {button && (
          <div className="text-center">
            <Button to={button.to} primary={options?.primary}>
              {button.label}
            </Button>
          </div>
        )}
      </Container>
    </>
  )
}

BigFiveBlogCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
  options: PropTypes.object,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.object,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      date: PropTypes.string,
      tag: PropTypes.string,
    })
  ).isRequired,
}

export default BigFiveBlogCard
