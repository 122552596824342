import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

import Headline from '@objects/headline'
import Container from '@objects/container'
import TracklineWrapper from '@objects/tracklineWrapper'
import Button from '@objects/button'
import { TeaserCopy } from '@objects/copy'
import { useIntl } from 'react-intl'

import { Link } from 'gatsby'
import Icon from '@objects/icon'

const StyledIcon = styled.div.attrs({
  className: 'text-2xl text-white bg-blue md:mt-2 p-4 mx-auto',
})`
  border-radius: 50%;
  line-height: 1;
  font-size: 43px;

  svg {
    max-width: initial;
  }
`

function IconTeaser({ className, link, icon, headline, copy }) {
  function renderIcon() {
    switch (icon) {
      case 'faq':
        return <Icon name="FAQ" />
      case 'docs':
        return <Icon name="Download" />
      case 'media':
        return <Icon name="Media" />
      case 'press':
        return <Icon name="Press" />
      default:
        return <Icon name="FAQ" />
    }
  }

  return (
    <Link className="flex flex-col mb-6 md:mb-0" to={link} data-testid="teaser">
      <StyledIcon>{renderIcon()}</StyledIcon>
      <div className="flex mt-3 mx-auto">
        <Headline
          level={3}
          className="mb-1 text-base font-medium leading-4"
          html={headline}
          ariaLabel={headline}
        />
        <TeaserCopy truncateextend={<Button textlink />} html={copy} />
      </div>
    </Link>
  )
}

IconTeaser.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['faq', 'docs', 'media']),
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
}

const ImageWrapper = styled.div.attrs({
  className: 'absolute left-0 top-0 pin-c w-full h-full',
})``

function NewsroomTeaser({
  className,
  headline,
  subheadline,
  button,
  background,
  options,
}) {
  const intl = useIntl()
  function renderButton({ button }) {
    if (button) {
      return (
        <div className="text-center mt-3 md:mt-8">
          <Button primary={options?.primary} to={button.to}>
            {button.label}
          </Button>
        </div>
      )
    }
    return null
  }

  if (!background) return <></>

  return (
    <TracklineWrapper
      topbottom={true}
      className={className}
      firstcircle="halfvert"
      secondcircle="t"
    >
      <ImageWrapper>
        <Img
          className="h-full"
          fluid={{ ...background.fluid, media: `(min-width: 0px)` }}
          backgroundColor="grey"
          alt={background.description}
        />
      </ImageWrapper>
      <Container
        className="z-10 content-center"
        role="region"
        aria-label={headline}
      >
        <Headline className="text-center mb-5" ariaLabel={headline}>
          {headline}
        </Headline>
        <div className="flex justify-center text-center">
          <TeaserCopy className="mb-8 max-w-2xl" html={subheadline} />
        </div>

        <Container className="mb-7 md:mb-9" nopadding role="group">
          <div className="grid grid-cols-2 md:grid-cols-4 max-w-2xl mx-auto">
            <IconTeaser
              className="mb-7 md:w-1/3"
              icon="media"
              link={intl.formatMessage({ id: 'path.media' })}
              headline="Media"
            />

            <IconTeaser
              className="mb-7 md:w-1/3"
              icon="docs"
              link={intl.formatMessage({ id: 'path.downloads' })}
              headline="Downloads"
            />

            <IconTeaser
              className="mb-7 md:w-1/3"
              icon="faq"
              link={intl.formatMessage({ id: 'path.faq' })}
              headline="FAQ"
            />

            <IconTeaser
              className="mb-7 md:w-1/3"
              icon="press"
              link={intl.formatMessage({ id: 'path.newsroom' })}
              headline="Presse"
            />
          </div>
        </Container>
        {renderButton({ button })}
      </Container>
    </TracklineWrapper>
  )
}

NewsroomTeaser.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
  options: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      tag: PropTypes.shape({
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      }).isRequired,
      date: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
  background: PropTypes.object,
}

export default styled(NewsroomTeaser)`
  ${tw`relative`}
`
