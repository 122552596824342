import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Parallax,
  Pagination,
  A11y,
  Autoplay,
} from 'swiper'
import { makeStyles } from '@material-ui/core/styles'

import styled from 'styled-components'
import tw from 'twin.macro'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

import Icon from '@objects/icon'

const NavigationButtons = styled.div`
  outline: none;
  font-size: 46px;
  width: 56px;
  height: 56px;
  ${tw`text-gray-light-300 transition duration-150`}
  &:hover {
    ${tw`text-gray-light`}
  }
  &:focus {
    outline: none;
  }
`

const useStyles = makeStyles((theme) => ({
  carouselRoot: {
    '&.swiper-container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      overflow: 'hidden',
      listStyle: 'none',
      padding: 0,
      /* Fix of Webkit flickering */
      zIndex: 1,
    },
    '& .swiper-wrapper': {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      transitionProperty: 'transform',
      boxSizing: 'content-box',
    },
    '& .swiper-container-android .swiper-slide, .swiper-wrapper': {
      transform: 'translate3d(0px, 0, 0)',
    },
    '& .swiper-slide': {
      flexShrink: 1,
      height: 'auto',
      position: 'relative',
      transitionProperty: 'transform',
    },

    '& .swiper-button-next': {
      width: '56px',
      height: '56px',
      background: 'hotpink',
      borderRadius: 999,
    },

    '& .swiper-pagination-bullets': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .swiper-pagination-bullet': {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      height: '16px',
      width: '16px',
      borderWidth: '1px',
      borderColor: 'rgba(90,126,151,1)',
      borderRadius: 999,
      margin: '0 10px',
      opacity: '.9',

      '&:focus, &:hover': {
        opacity: '.9',
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: '3px',
        bottom: '3px',
        left: '3px',
        right: '3px',
        transform: 'scale(0.01)',
        background: 'rgba(227,0,15,1)',
        transition: 'transform 0.3s ease, background 0.3s ease',
        borderRadius: 999,
      },

      '&.swiper-pagination-bullet-active': {
        opacity: 1,
        height: '20px',
        width: '20px',
        borderColor: 'rgba(227,0,15,1)',
        '&::before': {
          height: '12px',
          width: '12px',
          background: 'rgba(227,0,15,1)',
          borderRadius: 999,
          transform: 'scale(1)',
        },
      },
    },
    '& .swiper-notification': {
      position: 'absolute',
      left: 0,
      top: 0,
      pointerEvents: 'none',
      opacity: 0,
      zIndex: -1000,
    },
    '& .swiper-container-fade': {
      '&.swiper-container-free-mode': {
        '.swiper-slide': {
          transitionTimingFunction: 'ease-out',
        },
      },
      '& .swiper-slide': {
        pointerEvents: 'none',
        transitionProperty: 'opacity',
        '& .swiper-slide': {
          pointerEvents: 'none',
        },
      },
      '& .swiper-slide-active': {
        '&, & .swiper-slide-active': {
          pointerEvents: 'auto',
        },
      },
    },
  },
  nav: {
    cursor: 'pointer',
    zIndex: 1010,
    padding: theme.spacing(2),
    borderRadius: '50%',
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
  },
}))

function Carousel({ className, children, settings, ...props }) {
  const classes = useStyles()
  const intl = useIntl()

  SwiperCore.use([Parallax, A11y, Autoplay, Navigation, Pagination])

  return (
    <Swiper
      observer={true}
      observeParents={true}
      data-testid="carousel"
      modules={[Pagination]}
      pagination={{ clickable: true }}
      navigation={{
        prevEl: '.button.prev',
        nextEl: '.button.next',
      }}
      className={clsx(className, classes.carouselRoot)}
      a11y={{
        enabled: true,
        itemRoleDescriptionMessage: intl.formatMessage({
          id: 'carousel.label.itemRoleDescriptionMessage',
        }),
        paginationBulletMessage: intl.formatMessage({
          id: 'carousel.label.paginationBulletMessage',
        }),
        nextSlideMessage: intl.formatMessage({
          id: 'carousel.label.nextSlideMessage',
        }),
        prevSlideMessage: intl.formatMessage({
          id: 'carousel.label.prevSlideMessage',
        }),
        firstSlideMessage: intl.formatMessage({
          id: 'carousel.label.firstSlideMessage',
        }),
        lastSlideMessage: intl.formatMessage({
          id: 'carousel.label.lastSlideMessage',
        }),
        notificationClass: 'swiper-notification',
      }}
      {...props}
      {...settings}
    >
      {(() => {
        return React.Children.map(children, (child, index) => {
          return React.cloneElement(
            <SwiperSlide key={`carousel-slide-${index}`} virtualIndex={index}>
              {child}
            </SwiperSlide>
          )
        })
      })()}
      <>
        <NavigationButtons className="button prev">
          <Icon name="Prev" />
        </NavigationButtons>
        <NavigationButtons className="button next" data-testid="next">
          <Icon name="Next" />
        </NavigationButtons>
      </>
    </Swiper>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  slideChange: PropTypes.func,
  settings: PropTypes.object,
}

export default styled(Carousel).attrs({
  className: 'relative overflow-hidden',
})`
  z-index: 1;
  .swiper-slide {
    ${tw`transition-transform duration-150 h-auto relative mx-2`}

    transform: scale(.75);
    &.swiper-slide-active {
      transform: scale(1);
    }
  }
`
