import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import TabHead from './tabhead'
import TabPanel from './tabpanel'
import clsx from 'clsx'

const TabHeadType = (<TabHead />).type
const TabPanelType = (<TabPanel />).type

function Tabs({ className, children, activeTab, label }) {
  const [currentTab, setCurrentTab] = useState(activeTab || 0)
  const [heads, setHeads] = useState([])
  const [panels, setPanels] = useState([])

  useEffect(() => {
    setHeads(
      children
        .filter((child) => child.type === TabHeadType)
        .map((head, i) =>
          React.cloneElement(head, {
            ...head.props,
            key: i,
            onClick: () => {
              setCurrentTab(i)
              if (head.props.onClick) head.props.onClick()
            },
            selfindex: i,
            currentTab: currentTab,
          })
        )
    )
    setPanels(
      children
        .filter((child) => child.type === TabPanelType)
        .map((panel, i) =>
          React.cloneElement(panel, {
            ...panel.props,
            key: i,
            selfindex: i,
            currentTab: currentTab,
          })
        )
    )
  }, [children, currentTab])

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(activeTab)
    }
  }, [activeTab])

  function onKeyPress(ev) {
    let nextFocus = currentTab

    // Move right
    if (ev.key === 'ArrowRight' || ev.key === 'ArrowLeft') {
      if (ev.key === 'ArrowRight') {
        nextFocus++
        // If we're at the end, go to the start
        if (nextFocus >= heads.length) {
          nextFocus = 0
        }
        // Move left
      } else if (ev.key === 'ArrowLeft') {
        nextFocus--
        // If we're at the start, move to the end
        if (nextFocus < 0) {
          nextFocus = heads.length - 1
        }
      }

      setCurrentTab(nextFocus)
    }
  }

  return (
    <div className={clsx('relative', className)}>
      <div className="absolute top-0 left-0 w-full relative pt-4 lg:pt-6">
        <div
          className="flex flex-col sm:flex-row bg-white bg-opacity-95 rounded-full"
          role="group"
          aria-label={label}
          onKeyDown={(ev) => onKeyPress(ev, false)}
        >
          {heads}
        </div>
      </div>
      {panels}
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.array.isRequired,
  activeTab: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.string,
}

Tabs.defaultProps = {
  label: 'Tabs',
}

export default Tabs

export { TabHead, TabPanel }
