import React from 'react'
import PropTypes from 'prop-types'

function TabPanel({ children, selfindex, currentTab }) {
  return (
    <div
      className={'pt-6 pb-4 lg:pb-14'}
      id={`panel-${selfindex}`}
      role="group"
      aria-labelledby={`tab-${selfindex}`}
      hidden={selfindex === currentTab ? null : true}
    >
      {selfindex === currentTab && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.any,
  selfindex: PropTypes.number,
  currentTab: PropTypes.number,
}

export default TabPanel
