import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import QuoteIcon from '@static/img/icons/quote.inline.svg'
import Image from '@objects/image'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f0f3f4',
    display: 'flex',
    gap: '60px',
    padding: '56px 76.5px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',

    [theme.breakpoints.up('md')]: {
      alignItems: 'start',
      flexDirection: 'row',
      padding: '40px',
    },
  },
  image: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: '308px',
      height: '308px',
    },
    aspectRatio: '1/1',
    '& div': {
      height: '100%',
    },
    '& img': {
      height: 'inherit',
      objectFit: 'cover',
    },
  },
  icon: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    width: '88px',
    height: '66px',

    [theme.breakpoints.up('md')]: {
      width: '137px',
      height: '104px',
    },

    transform: 'translateY(50%)',
  },
  textWrapper: {
    maxWidth: '584px',
  },
  copy: {
    color: '#00385E',
    fontSize: '16px',
    lineHeight: '25px',

    [theme.breakpoints.up('md')]: {
      fontSize: '26px',
      lineHeight: '36px',
    },
  },
  byline: {
    color: '#575556',
    marginTop: '16px',
    fontSize: '14px',
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
  },
}))

function Quote({ image, copy, byline }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {image && (
        <div className={classes.image}>
          <Image plain alt={image.description} image={image.fluid} />
          <QuoteIcon className={classes.icon} />
        </div>
      )}
      {copy && (
        <div className={classes.textWrapper}>
          <div className={classes.copy}>{copy}</div>
          {byline && <div className={classes.byline}>{byline}</div>}
        </div>
      )}
    </div>
  )
}

Quote.propTypes = {
  image: PropTypes.object,
  copy: PropTypes.string,
  byline: PropTypes.string,
}

export default Quote
